import React from 'react'
import styled from 'styled-components'
import IconLinkArrowSvg from '../images/icons/link-arrow.svg'

import SEO from '../components/layout/seo'
import Layout from '../components/layout/layout'
import Container from '../components/layout/container'
import { Link } from 'gatsby'
import { BREAKPOINTS, ANIMATION } from '../styles/cssVariables'

const Content = styled.section`
  padding-top: 100px;
  padding-bottom: 200px;
  text-align: center;
`

const HomeCta = styled(Link)`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: none;
  font-family: Poppins, sans-serif;
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 3em;
  background-color: var(--color-blue) !important;
  color: #fff !important;
  margin-left: auto;
  margin-right: auto;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: var(--color-skobeloff) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
`
const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`

/**
 * Page shown when a link results in an HTTP 404 (Not Found) error.
 * Contains a short message & a CTA to return to the homepage.
 */
const PageTemplate404 = (props) => {
  return (
    <Layout location={props.location}>
      <SEO pageTitle="404 - Not Found" />

      <Content>
        <Container align="center" width={12}>
          <h2>Oops! This page doesn't exist!</h2>
          <HomeCta to="/">
            Back Home
            <Arrow />
          </HomeCta>
        </Container>
      </Content>
    </Layout>
  )
}

export default PageTemplate404
